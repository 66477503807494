import { bookGetById, bookSortingGetById } from '@/services/book'
import { examGetById, examSave, examCreate } from '@/services/exam.js'
import { examsCheckQuestions } from '@/services/exams.js'
import { courseGetById, courseSettingsGetById } from '@/services/course.js'
import {
  abilitiesGetByNodeId,
  curriculumGetById,
  getMyCurricula as serviceGetMyCurricula,
} from '@/services/curriculum'
import {
  questionGetById,
  questionGetByVersionId,
  questionSearchByNodeId,
  questionReport,
} from '@/services/question'
import { attachmentsGet } from '@/services/attachment'
import {
  settingsGetMaterialPickerSetting,
  settingsSetMaterialPickerSetting,
  settingsGetRecentlyPicked,
  settingsSetRecentlyPicked,
  settingsGetRecentSubject,
  settingsSetRecentSubject,
} from '@/services/settings'
import { gradeThresholdsGetbyCode } from '@/services/gradeThresholds'
import { groupsGetAll } from '@/services/group'
import { getTotalPointsPerGrade } from '@/utils/getTotalGradePointsPerAbility'
import { Difficulty, QuestionPointsMode, ExamType } from '@/constants'
import { getDifficulty } from '@/utils/difficulty'
import { calculateExamLimits } from '@/utils/matrixes/gradeThresholds'
import { useTokenStore } from '@/stores/token'
import { sendFeedback as _sendFeedback } from '@/services/feedback'

async function getExamById(id) {
  const exam = await examGetById(id)
  return await mapExamFromBackend(exam)
}

async function mapExamFromBackend(exam) {
  const abilityMap = await getAbilityMapForNodeId(exam.material.id)
  exam.parts.forEach((part) => {
    part.questions = part.questions.map((q) => {
      return updateQuestion(q, abilityMap)
    })
    const calculatorAllowed = part.settings.calculatorAllowed
    const digitalHeading = part.settings?.digital?.heading // it can be null also
    const digitalAnswerArea = part.settings?.digital?.answerArea // will be undefined next time after saving the exam

    part.settings = {
      calculatorAllowed,
      heading: digitalHeading || part.settings.heading,
      answerArea: digitalAnswerArea || part.settings.answerArea,
    }

    // retain "No student instructions." heading if digitalHeading is null
    if (digitalHeading === null || part.settings.heading === null) {
      part.settings.heading = ''
    }
  })
  exam.course = await getCourse(exam.course.id)
  // exam.settings.basic.type is moved to exam.type
  exam.type = exam.type || exam.settings.basic.type || ExamType.Exam
  return exam
}

async function checkExamQuestions(courseId, questions) {
  return await examsCheckQuestions(courseId, questions)
}

async function saveExam(exam) {
  const examForSaving = {
    name: exam.name,
    type: exam.type,
    group: exam.group,
    material: exam.material,
    settings: exam.settings,
    parts: exam.parts.map((part) => ({
      ...part,
      questions: part.questions.map((q) => q.questionVersionId),
    })),
  }
  const savedExam = await examSave(exam.id, examForSaving)
  return await mapExamFromBackend(savedExam)
}

async function createExam(exam, copiedFrom) {
  const examForSaving = {
    name: exam.name,
    type: exam.type,
    group: exam.group,
    material: exam.material,
    category: exam.category,
    copiedFrom,
    settings: exam.settings,
    parts: exam.parts.map((part) => ({
      ...part,
      questions: part.questions.map((q) => q.questionVersionId),
    })),
  }
  const examCreated = await examCreate(examForSaving)

  return await mapExamFromBackend(examCreated)
}

async function getAbilities(nodeId) {
  const abilityList = Object.entries(await abilitiesGetByNodeId(nodeId)).map(
    (a) => ({ id: a[0], name: a[1].name, group: a[1].group })
  )
  return abilityList
}

async function getConnectedAbilities(nodeId) {
  const abilities = await getAbilities(nodeId)
  return abilities.reduce((obj, a) => {
    obj[a.id] = a.group
    return obj
  }, {})
}

async function getAbilityMapForNodeId(nodeId) {
  const abilities = await getAbilities(nodeId)
  return abilities.reduce((obj, item) => {
    return {
      ...obj,
      [item.id]: item.name,
    }
  }, {})
}

function mapCriterias(primaryId, abilityMap, content, context) {
  const pointTypes = getPointTypes()
  // V2 content assumption
  return content
    .filter((sq) => sq.data.criterias)
    .flatMap((sq) =>
      sq.data.criterias.map((criteria, index) => ({
        criteriaIndex: index,
        subchapterId: context.subchapter.id,
        questionPrimaryId: primaryId,
        subQuestionId: sq.id,
        pointType: criteria.level,
        pointIndex: pointTypes.findIndex((pt) => pt.key === criteria.level),
        ability: abilityMap[criteria.ability],
        abilityKey: criteria.ability,
        points: 1,
      }))
    )
}

function mapAvailablePoints(criterias) {
  return criterias.reduce(
    (total, criteria) => {
      total[criteria.pointIndex]++
      return total
    },
    [0, 0, 0]
  )
}

function injectChapter(q, bookId) {
  const location = q.context.usedIn.find(
    (location) => location.book.id === Number(bookId)
  )
  if (location) {
    q.context.chapter = location.chapter
    q.context.subchapter = location.subchapter
    q.context.course = location.course
  }
}

async function getQuestion(id, versionId, bookId) {
  const abilityMap = await getAbilityMapForNodeId(bookId)
  const q = await questionGetById(id, versionId)
  return updateQuestion(q, abilityMap, bookId)
}

async function getQuestionByPrimaryId(id, bookId) {
  return await getQuestion(null, id, bookId)
}

async function refreshQuestionCriterias(_courseId, _q) {
  throw new Error('Not implemented')
}

async function uploadSolution() {
  throw new Error('Not implemented')
}

async function getQuestionByVersionId(versionId, bookId, abilityMap) {
  if (!abilityMap) {
    abilityMap = await getAbilityMapForNodeId(bookId)
  }
  const q = await questionGetByVersionId(versionId)
  return updateQuestion(q, abilityMap, bookId)
}

async function getHierarchyFromMaterial(bookId) {
  const book = await bookGetById(bookId)

  return {
    type: 'BOOK',
    hierarchy: book.children.map((chapter) => ({
      id: chapter.id,
      name: chapter.name,
      items: chapter.children.map((subchapter) => {
        return {
          id: subchapter.id,
          name: subchapter.name,
        }
      }),
    })),
  }
}

async function promiseQuestions(bookId, nodes, params) {
  const nodeIds = nodes.map((n) => n.id)
  const results = await questionSearchByNodeId(
    nodeIds.length > 0 ? nodeIds : [bookId],
    params
  )
  const questionIds = results.questions.map((q) => ({
    id: parseInt(q.id),
    questionVersionId: parseInt(q.questionVersionId),
  }))
  const abilityMap = await getAbilityMapForNodeId(bookId)
  return {
    facets: results.facets || [],
    totalCount: results.metadata.count,
    promises: questionIds.map((q, index) => {
      const promiseObject = {}
      promiseObject.id = q.id
      promiseObject.key = q.questionVersionId
      promiseObject.questionVersionId = q.questionVersionId
      promiseObject.promiseRetriever = () => {
        if (!promiseObject.promise) {
          promiseObject.promise = getQuestionByVersionId(
            q.questionVersionId,
            bookId,
            abilityMap
          ).catch((e) => {
            promiseObject.error = e
            return null
          })
        }
        return promiseObject.promise
      }
      if (index <= 5) {
        promiseObject.promiseRetriever()
      }
      return promiseObject
    }),
  }
}

async function reportQuestion(
  _questionId,
  versionId,
  _courseId,
  { type, comment }
) {
  return await questionReport(versionId, { type, comment })
}

function getPointTypes() {
  return [
    { key: 'e', name: Difficulty.EASY },
    { key: 'c', name: Difficulty.MEDIUM },
    { key: 'a', name: Difficulty.DIFFICULT },
  ]
}
async function getMyCurricula() {
  return await serviceGetMyCurricula()
}

async function getCurriculumById(id) {
  return await curriculumGetById(id)
}

// TODO: shared classes is not implemented in gauss yet so we same as groupsGetAll
async function getClassesAndSharedClasses(_userId, subjectId) {
  const classes = await groupsGetAll(subjectId)
  return classes
}

async function getCourse(courseId) {
  const course = await courseGetById(courseId)
  course.settings = await getCourseSettings(courseId)
  course.abilities = await getAbilities(courseId)
  return course
}

async function getCourseSettings(courseId) {
  const settings = await courseSettingsGetById(courseId)

  return {
    calculator: settings.CALCULATOR === '1',
    gradeThreshold: settings.GRADE_THRESHOLD || 'default',
    pointsMode: QuestionPointsMode.POINTS_TOTAL,
    displayCriteriasAsMatrixThreshold: 999,
    skipBlankAbilityRows: false,
  }
}

function updateQuestion(q, abilityMap, bookId) {
  if (bookId) {
    injectChapter(q, bookId)
  }
  q.primaryId = q.questionVersionId
  q.displayId = q.id + '-' + q.version
  q.criterias = mapCriterias(
    q.questionVersionId,
    abilityMap,
    q.content,
    q.context
  )
  q.availablePoints = mapAvailablePoints(q.criterias)
  q.difficulty = getQuestionDifficulty(q)
  delete q.context.studentAccess
  return q
}

async function getMaterialPickerSettings() {
  return await settingsGetMaterialPickerSetting()
}

async function setMaterialPickerSettings(courseId, materialId) {
  await settingsSetMaterialPickerSetting(courseId, materialId)
}

async function getRecentlyPickedMaterial() {
  return await settingsGetRecentlyPicked()
}

async function setRecentlyPickedMaterial(materials) {
  await settingsSetRecentlyPicked(materials)
}

async function getRecentSubject() {
  return await settingsGetRecentSubject()
}

async function setRecentSubject(curriculumId, subjectId) {
  return await settingsSetRecentSubject(curriculumId, subjectId)
}

const getQuestionDifficulty = (question) => {
  const pointTypes = getPointTypes().map((level) => level.key)
  const totalPointsPerGradeResult = getTotalPointsPerGrade(
    pointTypes,
    question.criterias
  )
  const { weightedMeanValue } = getDifficulty(totalPointsPerGradeResult)
  return weightedMeanValue
}

const bookSorting = async (bookId) => {
  const sorting = await bookSortingGetById(bookId)
  return sorting
}

const mapAttachmentToHtml = (attachment) => {
  if (attachment.contentType === 'audio') {
    return (
      `<audio controls src="${attachment.content.url}"></audio>` +
      attachment.content.text
    )
  }
  if (attachment.contentType === 'link') {
    return attachment.content.url
  }
  return attachment.content.html
}

async function getAttachmentsContent(attachments) {
  const attachmentIds = attachments.map((attachment) => attachment.id)
  return (await attachmentsGet(attachmentIds)).map((attachment) => ({
    id: attachment.id,
    title: attachment.title,
    author: attachment.author,
    type: attachment.contentType,
    // FIXME ideally we should refactor html in here and in
    // KMExamFacade so both provide something closer to KM 2.0 backend
    html: mapAttachmentToHtml(attachment),
  }))
}

async function sendFeedback(rating, comment) {
  return await _sendFeedback({
    rating: Number(rating),
    comment,
  })
}

function getQuestionEditorUrl(questionId = 0, materialId = 0, forceNew = true) {
  const tokenStore = useTokenStore()
  let url = `/qe/?token=${tokenStore.token}`
  if (questionId !== 0) {
    url += `&questionId=${questionId}`
  }
  if (forceNew) {
    url += '&new=1'
  }
  if (materialId !== 0) {
    url += '&materialId=' + materialId
  }
  return url
}

async function getDefaultLimits(exam) {
  const connectedAbilities = await getConnectedAbilities(exam.course.id)
  const gradeThresholdCode = exam.course.settings.gradeThreshold

  const { algorithm, thresholds } =
    await gradeThresholdsGetbyCode(gradeThresholdCode)

  const limits = calculateExamLimits(
    algorithm,
    thresholds,
    connectedAbilities,
    exam.parts.flatMap((part) => part.questions)
  )

  return { limits, connectedAbilities }
}

async function getQuestionForEditor(_questionId, _courseId = undefined) {
  throw new Error('Not implemented')
}
async function isQuestionUsedInExams(_questionId) {
  // Gauss never cares about questions being in exams
  return false
}

async function getPreferredBook(_courseId, _classId) {
  return undefined
}

async function hierachySummaryEnabledForCourse(_courseId) {
  return true
}

export default {
  mapExamFromBackend,
  getExamById,
  // getMyCourses,
  getCourse,
  getMyCurricula,
  getCurriculumById,
  getClassesAndSharedClasses,
  // getMyBooksByCourseId,
  getCourseSettings,
  checkExamQuestions,
  saveExam,
  createExam,
  getQuestion,
  getQuestionByPrimaryId,
  refreshQuestionCriterias,
  // getQuestionByVersionId,
  // findQuestions,
  promiseQuestions,
  reportQuestion,
  getHierarchyFromMaterial,
  getAbilities,
  getAttachmentsContent,
  getPointTypes,
  // getBooksInSeries,
  getMaterialPickerSettings,
  setMaterialPickerSettings,
  getRecentlyPickedMaterial,
  setRecentlyPickedMaterial,
  getRecentSubject,
  setRecentSubject,
  bookSorting,
  sendFeedback,
  uploadSolution,
  getQuestionEditorUrl,
  getDefaultLimits,
  getQuestionForEditor,
  isQuestionUsedInExams,
  getPreferredBook,
  hierachySummaryEnabledForCourse,
}
